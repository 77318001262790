import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/web/Header/Header';
import Footer from './components/web/Footer/footer';
import HomePage from './components/web/HomePage/HomePage'; // A new component for the home page
import News from './components/web/News/NewsPage'; // A new component for the second page
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivacyPolicy from './components/web/Privacy/privacy';
// import Dashboard from './components/Dashboard/Dashboard';
import AboutusPage from './components/web/Aboutus/AboutusPage';
import SolutionsPage from './components/web/Solutions/SolutionsPage';
import NewsEdit from './components/web/News/News_edit';
import CookieConsent from './components/web/CookieConsent/CookieConsent';
import TermsOfUse from './components/web/TermsOfUse/TermsOfUse';
import SvgMapComponent from './components/web/GBA/svgmap';
import HongKongMap from './components/web/GBA/HongKongMap';
import HongKongMap02 from './components/web/GBA/HongKongMap02'
import ResourcesPage from './components/web/Resources/ResourcesPage'

function App() {
  return (
    <Router>
            <Helmet>
                <title>PHOENIX.AI - To leverage A.I. for the improvement of people's lives and the benefit of society.</title>
                <meta name="description" content="To leverage A.I. for the improvement of people's lives and the benefit of society." />
                <meta name="keywords" content="Artificial Intelligence, AI, Society, improvement, people's lives, Ethical AI, Hong Kong AI, benefit of society" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://phoenix.ai/" />
                {/* Security headers */}
                <meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline';" />
                <meta http-equiv="X-Frame-Options" content="DENY" />
                {/* Consider adding HTTP Strict Transport Security (HSTS) via headers for HTTPS enforcement */}
            </Helmet>
      <CookieConsent />

      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/news" element={<News />} />
          <Route path="/newsedit" element={<NewsEdit />} />
          <Route path="/aboutus" element={<AboutusPage />} />
          <Route path="/Solutions" element={<SolutionsPage />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          <Route path="/svgmap" element={<SvgMapComponent width={1160} height={740}  />} />
          <Route path="/hkmap" element={<HongKongMap />} />
          <Route path="/hkmap02" element={<HongKongMap02 />} />
          <Route path="/resources" element={<ResourcesPage />} />


          {/* <Route path="/dashboard" element={<Dashboard />} /> */}


        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;